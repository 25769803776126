<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <a :href="absoluteUrl()" class="logo logo-dark">
              <span class="logo-sm">
                <img :src="absoluteUrl('/images/logos/logo-sm-dark.webp')" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img :src="absoluteUrl('/images/logos/logo-dark.webp')" alt="" height="27" />
              </span>
            </a>

            <a :href="absoluteUrl()" class="logo logo-light')">
              <span class="logo-sm">
                <img :src="absoluteUrl('/images/logos/logo-sm-light.webp')" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img :src="absoluteUrl('/images/logos/logo-light.webp')" alt="" height="27" />
              </span>
            </a>
          </div>

          <button
            type="button"
            class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            "
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <div class="d-none d-lg-flex align-items-center ps-2">
            <h5 class="mb-0">Staff's Dashboard </h5>
          </div>
          <!-- App Search-->
        </div>

        <div class="d-flex align-items-center">
          <AppNotification />
          <AppProfile />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { SimpleBar } from "simplebar-vue3";
import AppProfile from "./AppProfile.vue";
import AppNotification from "./AppNotification.vue";

/**
 * Nav-bar Component
 */
export default {
  components: {
    SimpleBar, AppProfile,
    AppNotification
  },
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

  },
  methods: {
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
  computed: {},
};
</script>

