
<template>
  <div id="layout-wrapper">
    <AppNavbar />
    <div>
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <a :href="absoluteUrl()" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="absoluteUrl('/images/logos/logo-sm-dark.webp')" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img :src="absoluteUrl('/images/logos/logo-dark.webp')" alt="" height="27" />
            </span>
          </a>
          <!-- Light Logo-->
          <a :href="absoluteUrl()" class="logo logo-light">
            <span class="logo-sm">
              <img :src="absoluteUrl('/images/logos/logo-sm-light.webp')" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img :src="absoluteUrl('/images/logos/logo-light.webp')" alt="" height="27" />
            </span>
          </a>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <SimpleBar id="scrollbar" class="h-100" ref="scrollbar">
          <AppMenu></AppMenu>
        </SimpleBar>
        <div class="sidebar-background">
          <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
        </div>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div @click="toggleMenu()" class="vertical-overlay"></div>
    </div>
    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- <PageHeader :title="pageTitle" :items="breadCrumb" /> -->
          <RouterView />
        </div>
      </div>
      <AppFooter />
    </div>
    <vue-easy-lightbox :visible="lightbox.visible" :imgs="lightbox.files"
    :index="lightbox.index" @hide="initializeLightBox()"></vue-easy-lightbox>
  </div>
</template>

<script>
import router from "@/router";
import { SimpleBar } from "simplebar-vue3";
import { layoutComputed } from "@/store/helpers";

import AppNavbar from "@/components/AppNavbar";
import AppMenu from "@/components/AppMenu.vue";
import AppFooter from "@/components/AppFooter";
import PageHeader from "@/components/PageHeader";
import VueEasyLightbox from "vue-easy-lightbox";

/**
 * Vertical layout
 */
export default {
  components: { AppNavbar, AppFooter, SimpleBar, AppMenu, PageHeader, VueEasyLightbox },
  data() {
    return {
      isMenuCondensed: false,
      pageTitle: "Dashboard",
      breadCrumb: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...layoutComputed,
    lightbox(){
      return this.$store.state.lightbox
    }
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed"); 
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("vertical-sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("vertical-sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("vertical-sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    setEnvironment(){
      document.documentElement.setAttribute("data-layout-mode", this.mode);
      document.documentElement.setAttribute("data-sidebar", this.sidebarColor);
      document.documentElement.setAttribute("data-layout-style","default");  
      document.documentElement.setAttribute("data-layout-position", this.position);
      document.documentElement.setAttribute("data-sidebar-size", this.sidebarSize);
      document.documentElement.setAttribute("data-topbar", this.topbar);
      document.documentElement.setAttribute("data-layout-width", this.layoutWidth);
      document.documentElement.setAttribute("data-layout", this.layoutType);
    },
    initializeLightBox(){
      this.$store.commit('TOGGLE_LIGHT_BOX')
    }
  },
  mounted() {
    this.setEnvironment();
  },
};
</script>

<style scoped>
.sidebar-background {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
    opacity: .1;
    /* background-image: url(/images/sidebar/img-2.jpg); */
}

.circles-container {
    position: absolute;
    width: 90%;
    bottom: -40%
}

.circle-back, .circle-front{
    background-color: #ff77005d;
    position: relative;
    width: 250%;
    padding-top: 250%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -158%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -279%;
    z-index: 4
}

.navbar-brand-box{
  background: var(--bs-body-bg);
  margin-right: -1px;
}
</style>